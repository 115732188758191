import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import useLocalStorage from 'use-local-storage'
import useSiteMetadata from './SiteMetadata'

const Navbar = ({ defaultDark, pageMenuItems = [] }) => {
    const { menuItems } = useSiteMetadata() ?? { menuItems: [] }

    function toggleNavigation() {
        function toggleClass(target, className) {
            if (Array.isArray(target)) {
                target.forEach(t => {
                    toggleClass(t[0], t[1]);
                })
                return
            }
            target.setAttribute(
                'class',
                target.getAttribute('class').includes('--opened')
                    ? className
                    : `${className} ${className}--opened`
            );
        }

        const nav = document.querySelector('nav.navigation');
        const button = document.querySelector('button.navigation-burger')
        const pageHeader = document.querySelector('.page__header')

        toggleClass([[nav, 'navigation'], [button, 'navigation-burger'], [pageHeader, 'page__header--home page__header']]);
    }

    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

    const toggleTheme = () => {
        return setTheme(theme === 'light' ? 'dark' : 'light')
    }

    const darkModeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24" /><path d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z" /></svg>;
    const lightModeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24" /><path d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" /></svg>

    return (
        <div className="container container__compressed">
            <Helmet bodyAttributes={{
                'data-theme': theme
            }} />
            <header className="header">
                <div className="header-logo">
                    <Link style={{ textDecoration: 'none' }} to="/">
                        <span className="header-logo__text">Blaze</span>
                    </Link>
                </div>
                <div data-focus-lock-disabled="disabled" className="header__focus-trap">
                    <button onClick={toggleNavigation} className="navigation-burger" id="burger" type="button" tabIndex="0" aria-haspopup="true" aria-expanded="false" aria-controls="navigation" aria-label="Mobile navigation trigger">
                        <span className="navigation-burger__icon">
                            <span className="navigation-burger__figure navigation-burger__figure--first"></span>
                            <span className="navigation-burger__figure navigation-burger__figure--second"></span>
                            <span className="navigation-burger__label">Menu</span>
                        </span>
                    </button>
                    <nav className="navigation" id="navigation" aria-labelledby="burger">
                        <ul className="navigation__list">
                            {[...menuItems, ...pageMenuItems].map((item, index) => (
                                <NavbarMenuItem key={index} title={item.title} link={item.link} children={item.children} />
                            ))}
                            <li className="navigation__item">
                                <button className="themeButton" onClick={toggleTheme}>
                                    {theme === 'light' ? darkModeIcon : lightModeIcon}
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Navbar

const NavbarMenuItem = ({ title, link, children }) => {
    if (children) {
        return <NavbarMenuDropdownItem title={title} link={link} children={children} />
    }

    return <NavbarMenuLinkItem title={title} link={link} />
}

const NavbarMenuLinkItem = ({ title, link, onMouseEnter }) => {
    return (
        <li className="navigation__item">
            <Link onMouseEnter={onMouseEnter} partiallyActive={true} activeClassName={link === "/" ? "" : "navigation__link--current"} className="navigation__link" title={title} to={link}>{title}</Link>
        </li>
    )
}

const NavbarMenuDropdownItem = ({ title, link, children }) => {
    const [open, setOpen] = useState(false);

    return (
        <div
            className="navigation__item navigation__item-dropdown"
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <NavbarMenuLinkItem onMouseEnter={() => setOpen(true)} title={title} link={link} />
            <Fragment>
                <div className={`navigation__dropdown ${open ? 'navigation__dropdown--opened' : ''}`}
                    style={{ maxHeight: open ? `500px` : '0px' }}
                    onMouseLeave={() => setOpen(false)}
                >
                    <div className="navigation__dropdown__container">
                        <div className="navigation__dropdown__container--inner">
                            {children.map((child, index) => (
                                <>
                                    <Link key={index} partiallyActive={true} className="navigation__link--dropdown" title={child.title} to={child.link}>{child.title}</Link>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </Fragment>
        </div>
    )
}